const PLANE = new Float32Array([0, 0, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1]);

const WEBGL = window.WebGLRenderingContext;

export default class WebGL {
	constructor( container ){

		this.container = container;

		this.layers = new Array();

		this.textureIndex = 0;

		this.canvas = document.createElement("canvas");

		this.canvas.classList.add("webgl");

		this.container.appendChild(this.canvas);

		this.gl = this.canvas.getContext("webgl", {
			antialias: true,
			alpha: false,
			premultipliedAlpha: false
		});

		this.passes = new Array();

		this.resize();

		window.addEventListener("resize", this.resize.bind(this), false);

		return this;

	}
	start(){

		this.stop();

		this.animationFrame = requestAnimationFrame(this.update.bind(this));

		return this;

	}
	stop(){

		cancelAnimationFrame(this.animationFrame);

		return this;

	}
	resize(){

		this.boundings = this.container.getBoundingClientRect();

		this.canvas.width = this.boundings.width * devicePixelRatio;

		this.canvas.height = this.boundings.height * devicePixelRatio;

		for( let framebuffer of this.passes ){

			this.gl.bindTexture(WEBGL.TEXTURE_2D, framebuffer.texture.instance);

			this.gl.texImage2D(WEBGL.TEXTURE_2D, 0, WEBGL.RGBA, this.canvas.width, this.canvas.height, 0, WEBGL.RGBA, WEBGL.UNSIGNED_BYTE, null);

		}

		return this;

	}
	addLayer( options ){

		var layer = this.createLayer(options);

		this.layers.push(layer);

		return this;

	}
	updateLayer({ program, textures, sources }){

		var uniformCount = this.gl.getProgramParameter(program, WEBGL.ACTIVE_UNIFORMS);

		for( let index = 0; index < uniformCount; index++ ){

			let { name, size, type } = this.gl.getActiveUniform(program, index);

			let source = sources ? sources[name] : null;

			if( type == WEBGL.SAMPLER_2D && source != null ){

				let value = source && source.value ? source.value : source;

				this.gl.bindTexture(WEBGL.TEXTURE_2D, textures[name].instance);

				this.gl.texImage2D(WEBGL.TEXTURE_2D, 0, WEBGL.RGBA, WEBGL.RGBA, WEBGL.UNSIGNED_BYTE, value);

			}

		};

		return this;

	}
	update( now ){

		this.animationFrame = requestAnimationFrame(this.update.bind(this));

		this.gl.bindFramebuffer(WEBGL.FRAMEBUFFER, this.passes.length > 0 ? this.passes[0].framebuffer : null);

		this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);

		this.gl.clearColor(0, 0, 0, 0);

		this.gl.clear(WEBGL.COLOR_BUFFER_BIT);

		for( let layer of this.layers ){

			this.gl.useProgram(layer.program);

			this.updateLayer(layer);

			this.gl.enableVertexAttribArray(layer.attributes.position);
			this.gl.bindBuffer(WEBGL.ARRAY_BUFFER, layer.buffers.position);
			this.gl.vertexAttribPointer(layer.attributes.position, 2, WEBGL.FLOAT, false, 0, 0);

			this.gl.enableVertexAttribArray(layer.attributes.uv);
			this.gl.bindBuffer(WEBGL.ARRAY_BUFFER, layer.buffers.uv);
			this.gl.vertexAttribPointer(layer.attributes.uv, 2, WEBGL.FLOAT, false, 0, 0);

			this.gl.drawArrays(WEBGL.TRIANGLES, 0, 6);

		}

		for( let index = 0, length = this.passes.length; index < length; index++ ){

			let pass = this.passes[index];

			let nextPass = this.passes[index + 1];

			let target = nextPass ? nextPass.framebuffer : null;

			this.gl.bindFramebuffer(WEBGL.FRAMEBUFFER, target);

			this.gl.bindTexture(WEBGL.TEXTURE_2D, pass.texture.instance);

			this.gl.clearColor(1, 0, 0, 0);
			
			this.gl.useProgram(pass.layer.program);

			this.gl.uniform1f(pass.layer.uniforms.time.location, now);

			this.gl.enableVertexAttribArray(pass.layer.attributes.position);
			this.gl.bindBuffer(WEBGL.ARRAY_BUFFER, pass.layer.buffers.position);
			this.gl.vertexAttribPointer(pass.layer.attributes.position, 2, WEBGL.FLOAT, false, 0, 0);

			this.gl.enableVertexAttribArray(pass.layer.attributes.uv);
			this.gl.bindBuffer(WEBGL.ARRAY_BUFFER, pass.layer.buffers.uv);
			this.gl.vertexAttribPointer(pass.layer.attributes.uv, 2, WEBGL.FLOAT, false, 0, 0);

			this.gl.drawArrays(WEBGL.TRIANGLES, 0, 6);

		}

		return this;

	}
	createLayer({ vertex, fragment, uniforms: sources }){

		var vertexShader = this.createShader(WEBGL.VERTEX_SHADER, vertex);

		var fragmentShader = this.createShader(WEBGL.FRAGMENT_SHADER, fragment);

		var program = this.createProgram(vertexShader, fragmentShader);

		var attributes = {
			position: this.gl.getAttribLocation(program, "position"),
			uv: this.gl.getAttribLocation(program, "uv")
		};

		var buffers = {
			position: this.createBuffer(PLANE),
			uv: this.createBuffer(PLANE)
		};

		var uniforms = new Object();

		var textures = new Object();

		var uniformCount = this.gl.getProgramParameter(program, WEBGL.ACTIVE_UNIFORMS);

		for( let index = 0; index < uniformCount; index++ ){

			let { name, size, type } = this.gl.getActiveUniform(program, index);

			let location = this.gl.getUniformLocation(program, name);

			let source = sources ? sources[name] : null;

			if( type == WEBGL.SAMPLER_2D ){

				let value = source && source.value ? source.value : source;

				let wrapS = source && source.wrapS ? source.wrapS : null;

				let wrapT = source && source.wrapT ? source.wrapT : null;

				textures[name] = this.createTexture(location, name, value, null, null, wrapS, wrapT);

				source = textures[name].index;

			}

			uniforms[name] = new Proxy({}, {
				get( target, property, receiver ){

					return target[property];

				},
				set: ( target, property, value )=>{

					if( property == "type" || property == "location" || property == "size" ){

						if( target[property] == undefined ){

							target[property] = value;

							return true;

						}
						else {

							return false;

						}

					}
					else if( property == "value" ){

						target.value = value;

						let { type, size, location } = target;

						if( type == WEBGL.FLOAT ){

							this.gl[`uniform${ size }f`](location, value);

						}
						else if( type == WEBGL.INT ){

							this.gl[`uniform${ size }i`](location, value);

						}
						else if( type == WEBGL.SAMPLER_2D ){

							this.gl.uniform1i(location, value);

						}

					}

					return true;

				}
			});

			Object.assign(uniforms[name], { type, size, location, value: source });

		}

		return { program, attributes, buffers, uniforms, textures, sources };

	}
	createShader( type, source ){

		var shader = this.gl.createShader(type);

		this.gl.shaderSource(shader, source);

		this.gl.compileShader(shader);

		if( !this.gl.getShaderParameter(shader, WEBGL.COMPILE_STATUS) ){

			let error = this.gl.getShaderInfoLog(shader);

			this.gl.deleteShader(shader);

			throw new Error(`Fail shader compilation: \n\r${ error }`);

		}

		return shader;

	}
	createTexture( location, name, source, width = null, height = null, wrapS, wrapT ){

		var texture = this.gl.createTexture();

		var index = this.textureIndex;

		this.gl.activeTexture(WEBGL.TEXTURE0 + index);

		this.gl.bindTexture(WEBGL.TEXTURE_2D, texture);

		this.gl.pixelStorei(WEBGL.UNPACK_FLIP_Y_WEBGL, true);

		this.gl.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_WRAP_S, wrapS || WEBGL.CLAMP_TO_EDGE);
		this.gl.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_WRAP_T, wrapT || WEBGL.CLAMP_TO_EDGE);

		this.gl.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_MIN_FILTER, WEBGL.NEAREST);
		this.gl.texParameteri(WEBGL.TEXTURE_2D, WEBGL.TEXTURE_MAG_FILTER, WEBGL.NEAREST);

		if( source != undefined ){

			this.gl.texImage2D(WEBGL.TEXTURE_2D, 0, WEBGL.RGBA, WEBGL.RGBA, WEBGL.UNSIGNED_BYTE, source);

		}
		else {

			this.gl.texImage2D(WEBGL.TEXTURE_2D, 0, WEBGL.RGBA, width, height, 0, WEBGL.RGBA, WEBGL.UNSIGNED_BYTE, null);

		}

		this.textureIndex++;

		return { instance: texture, index };

	}
	addPass({ fragment, uniforms }){

		var layer = this.createLayer({
			vertex: require("../../shaders/post-processing.vs"),
			fragment,
			uniforms
		});

		var textureLocation = this.gl.getUniformLocation(layer.program, "texture");

		var texture = this.createTexture(textureLocation, "texture", null, this.canvas.width, this.canvas.height);

		this.gl.uniform1i(textureLocation, texture.index);

		var framebuffer = this.gl.createFramebuffer();

		this.gl.bindFramebuffer(WEBGL.FRAMEBUFFER, framebuffer);

		this.gl.framebufferTexture2D(WEBGL.FRAMEBUFFER, WEBGL.COLOR_ATTACHMENT0, WEBGL.TEXTURE_2D, texture.instance, 0);

		var index = this.passes.push({ framebuffer, texture, layer });

		return this.passes[index - 1];

	}
	createProgram( vertexShader, fragmentShader ){

		var program = this.gl.createProgram();

		this.gl.attachShader(program, vertexShader);

		this.gl.attachShader(program, fragmentShader);

		this.gl.linkProgram(program);

		if( !this.gl.getProgramParameter(program, WEBGL.LINK_STATUS) ){

			let error = this.gl.getProgramInfoLog(program);

			this.gl.deleteProgram(program);

			throw new Error(`Fail program linking: (${ error.code }) — ${ error.message }`);

		}

		this.gl.useProgram(program);

		return program;

	}
	createBuffer( vertices, type = WEBGL.ARRAY_BUFFER ){

		var buffer = this.gl.createBuffer();

		this.gl.bindBuffer(type, buffer);

		this.gl.bufferData(type, vertices, WEBGL.STATIC_DRAW);

		return buffer;

	}
}