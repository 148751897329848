import "@babel/polyfill";
import Speech from "./modules/speech.js";
import MovingLogo from "./modules/moving-logo.js";

import { dataAPI } from "../../config.js";

require("../markups/index.html");
require("../styles/app.scss");

const DEGREE = Math.PI / 180;
const AUDIO_SAMPLES = 44100;
const ROBOT_AMOUNT = 2.5;

const COLORS = {
	PINK: "#FF1FC7",
	GREEN: "#00FC37",
	YELLOW: "#FFFA3E",
	BLUE: "#00D9F7"
};

window.AudioContext = window.AudioContext || window.webkitAudioContext;

var url = new URL(location.href);

var user = {
	first_name: "",
	last_name: "",
	email: "",
	will_come: url.searchParams.get("will_come") == 1 ? true : false
};

var queries = location.href.match(/((?:email|first_name|last_name)=[^&]+)/g) || [];

for( let query of queries ){

	let [ key, value ] = query.split("=");

	user[key] = atob(value);

}

console.log(queries, user);

function sendPersonalDatas( user ){

	fetch(dataAPI, {
		method: "POST",
		body: JSON.stringify(user)
	});

}

sendPersonalDatas(user);

window.addEventListener("beforeunload", ()=>{

	window.scrollTo(0, 0);

}, false);

document.addEventListener("DOMContentLoaded", async ()=>{

	new MovingLogo(document.querySelector("h1"));

	var audioContext = new AudioContext({
		sampleRate: 16000
	});

	var robotVoice = audioContext.createWaveShaper();

	var curve = new Float32Array(AUDIO_SAMPLES);

	for( let index = 0; index < AUDIO_SAMPLES; index++ ){

		let x = index * 2 / AUDIO_SAMPLES - 1;

		curve[index] = (Math.PI + ROBOT_AMOUNT) * x * 20 * DEGREE / (Math.PI + ROBOT_AMOUNT * Math.abs(x));

	}

	robotVoice.curve = curve;

	robotVoice.oversample = "4x";

	// Firefox do not support audio node loopback to itself
	if( navigator.userAgent.toLowerCase().includes("firefox") == false ){

		robotVoice.connect(robotVoice);

	}

	robotVoice.connect(audioContext.destination);

	var header = document.querySelector("header");

	var confirmTexts = header.querySelectorAll(".text.confirm");

	var questionsTexts = header.querySelectorAll(".text.ask");

	var main = document.querySelector("main");

	var confirmIndex = Math.round(Math.random() * (confirmTexts.length - 1));

	var currentQuestionIndex = 0;

	var completed = false;

	var personalSpeech = new Speech({
		container: header,
		audioContext: audioContext,
		audioDestination: robotVoice,
		autoStart: false,
		textContainer: user.will_come ? confirmTexts[confirmIndex] : questionsTexts[0],
		onComplete(){

			completed = true;

			document.documentElement.classList.add("allow-scroll");

			main.classList.add("visible");

		},
		variables: {
			username: {
				value: user.first_name,
				color: COLORS.GREEN
			},
			invites: {
				value: "invités",
				color: COLORS.BLUE
			},
			changer: {
				value: "changer",
				color: COLORS.GREEN
			},
			exposition: {
				value: "exposition",
				color: COLORS.PINK
			},
			excellent: {
				value: "excellent",
				color: COLORS.BLUE
			},
			raisonnable: {
				value: "raisonnable",
				color: COLORS.GREEN
			},
			ajouter: {
				value: "ajouter",
				color: COLORS.BLUE
			},
			"take-over": {
				value: "Take Over",
				color: COLORS.YELLOW
			},
			oui: {
				value: "oui",
				color: COLORS.GREEN,
				onClick(){

					user.will_come = true;

					sendPersonalDatas(user);

					personalSpeech.textContainer = header.querySelector(".text.confirm");

				}
			},
			non: {
				value: "non",
				color: COLORS.PINK,
				onClick(){

					user.will_come = false;

					sendPersonalDatas(user);

					personalSpeech.textContainer = questionsTexts[++currentQuestionIndex % questionsTexts.length];

				}
			}
		},
		pronounce: {
			"*$!@#&%§*": "Saperlipopette!",
			"oui / non": "oui ou non",
			"Mazarine Creative Intelligence": "Mazarine Créative In-téllidjence"
		}
	});

	var soundPopin = document.querySelector("#SoundPrompt");

	if( audioContext.state != "running" ){

		audioContext.suspend();

		new Speech({
			container: soundPopin,
			variables: {
				"activer-le-son": {
					value: "activer le son",
					color: COLORS.GREEN,
					onClick(){

						audioContext.resume();

						setTimeout(()=>{

							personalSpeech.start(true);

						}, 1000);

						soundPopin.classList.add("complete");

					}
				},
				refuser: {
					value: "refuser",
					color: COLORS.PINK,
					onClick(){

						audioContext.suspend();

						setTimeout(()=>{

							personalSpeech.start(false);

						}, 1000);

						soundPopin.classList.add("complete");

					}
				}
			}
		});

	}
	else {

		setTimeout(()=>{

			audioContext.resume();

			personalSpeech.start(true);

		}, 1000);

		soundPopin.classList.add("complete");

	}

	var presentationSpeech = new Speech({
		container: document.querySelector("#Presentation .left"),
		variables: {
			code: {
				color: COLORS.GREEN
			},
			"take-over-once": {
				value: "take over",
				color: COLORS.YELLOW
			},
			future: {
				color: COLORS.BLUE
			},
			"take-over-twice": {
				value: "take over",
				color: COLORS.PINK
			}
		}
	});

	function scrollHandler( event ){

		if( completed != true ){

			event.preventDefault();

		}

	}

	window.addEventListener("wheel", scrollHandler, { passive: false });

	window.addEventListener("touchmove", scrollHandler, { passive: false });

}, false);