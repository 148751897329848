const TYPES = {
	AWAIT: 0,
	EVERY: 1
};

const ACTIONS = new Array();

export default class Timer {
	static await( duration ){

		return new Promise(( resolve )=>{

			var time = performance.now() + duration;

			ACTIONS.push({ type: TYPES.AWAIT, time, resolve });

		});

	}
	static every( interval, duration, callback ){

		return new Promise(( resolve )=>{

			var start = performance.now();

			var intervalConstructor = null;

			if( interval instanceof Function ){

				intervalConstructor = interval;

				interval = intervalConstructor();

			}

			ACTIONS.push({
				type: TYPES.EVERY,
				begin: start,
				start, interval, duration, intervalConstructor,
				resolve,
				callback
			});

		});

	}
}

async function checkTimer(){

	requestAnimationFrame(checkTimer);

	var now = performance.now();

	for( let index = (ACTIONS.length - 1); index >= 0; index-- ){

		let { type } = ACTIONS[index];

		if( type == TYPES.AWAIT ){

			let { time, resolve } = ACTIONS[index];

			if( now >= time ){

				resolve();

				ACTIONS.splice(index, 1);

			}

		}
		else if( type == TYPES.EVERY ){

			let action = ACTIONS[index];

			let { begin, start, interval, intervalConstructor, duration, callback, resolve } = action;

			if( now >= (start + interval) ){

				ACTIONS.splice(index, 1);

				let keepContinue = await callback();

				if( now <= (begin + duration) && ((!intervalConstructor && keepContinue != false) || (intervalConstructor && keepContinue != false)) ){

					if( intervalConstructor != null ){

						interval = intervalConstructor();

					}

					ACTIONS.push({
						type,
						begin,
						start: performance.now(),
						interval,
						intervalConstructor,
						duration,
						callback,
						resolve
					});

				}
				else {

					resolve();

				}

			}

		}

	}

}

requestAnimationFrame(checkTimer);