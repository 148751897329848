export default class EventManager {
	constructor( scope ){

		this.scope = scope;

		this.events = new Array();

		return this;

	}
	add( target, type, action, options = false ){

		var event = {
			target: target,
			type: type,
			action: action.bind(this.scope),
			originalAction: action,
			options: options
		};

		if( target.addEventListener instanceof Function ){

			target.addEventListener(event.type, event.action, event.options);

		}

		this.events.push(event);

		return this;

	}
	remove( target, type, action, options = false ){

		for( let index = this.events.length - 1; index >= 0; index-- ){

			let event = this.events[index];

			if( (target === event.target || target === undefined) && (type === event.type || type === undefined) && (action === event.originalAction || action === undefined) && options === event.options ){

				if( event.target.removeEventListener instanceof Function ){

					event.target.removeEventListener(event.type, event.action, event.options);

				}

				this.events.splice(index, 1);

			}

		}

		return this;

	}
	trigger( target, type, options = null ){

		for( let event of this.events ){

			if( event.target === target && event.type === type ){

				var customEvent = new CustomEvent(type, options);

				if( target.dispatchEvent instanceof Function ){

					target.dispatchEvent(customEvent);

				}
				else {

					event.action();

				}

			}

		}

	}
	includes( target, type, action, options = false ){

		var alreadyExists = false;

		for( let event of this.events ){

			if( event.target === target && event.type === type && event.originalAction === action && event.options === options ){

				alreadyExists = true;

			}

		}

		return alreadyExists;

	}
}
