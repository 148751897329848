import EventManager from "../components/event-manager.js";

const EFFECT_MAX_DISTANCE = 200;

export default class MovingLogo {
	constructor( container ){

		this.events = new EventManager(this);

		this.container = container;

		this.letters = Array.from(this.container.querySelectorAll(".letter")).map(( letter )=>{

			var wrapper = letter.querySelector(".wrapper");

			var position = { x: 0, y: 0 };

			var targetPosition = { x: 0, y: 0 };

			return { letter, wrapper, position, targetPosition };

		});

		this.events.add(this.container, "mousemove", this.mouseMove);

		this.events.add(this.container, "touchend", event => event.preventDefault());

		this.events.add(this.container, "mouseleave", this.mouseLeave);



		return this;

	}
	mouseMove( event ){

		this.container.classList.remove("use-transitions");

		var boundings = this.container.getBoundingClientRect();

		var letterY = boundings.height / 2;

		for( let { letter, wrapper } of this.letters ){

			let { left, width } = letter.getBoundingClientRect();

			let letterX = left + (width / 2);

			let deltaX = (event.clientX - boundings.left) - letterX;

			let deltaY = (event.clientY - boundings.top) - letterY;

			let distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));

			if( distance < EFFECT_MAX_DISTANCE ){

				let force = 1 - (distance / EFFECT_MAX_DISTANCE);

				wrapper.style.transform = `translateX(${ -deltaX * force }px) translateY(${ -deltaY * force }px)`;

			}
			else {

				wrapper.style.transform = "";

			}

		}

		return this;

	}
	mouseLeave(){

		this.container.classList.add("use-transitions");

		for( let { container, wrapper } of this.letters ){

			wrapper.style.transform = "";

		}

		return this;

	}
}