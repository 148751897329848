export default class Easings {
	static easeInOutCubic( progress ){

		if( progress < 0.5 ){

			return 4 * progress * progress * progress;

		}
		else {

			return (progress - 1) * (2 * progress - 2) * (2 * progress - 2) + 1;

		}

	}
}