import EventManager from "../components/event-manager.js";
import WebGL from "./webgl.js";
import Timer from "../components/timer.js";
import Easings from "../components/easings.js";

import { speechAPI } from "../../../config.js";

const DEFAULT_TEXT_COLOR = "#FFFFFF";

export default class Speech {
	constructor({ container, variables, pronounce = null, textContainer = null, audioContext = null, audioDestination = null, autoStart = true, onComplete = null }){

		this.events = new EventManager(this);

		this.container = container;

		this.variables = variables;

		this.audioContext = audioContext;

		this.audioDestination = audioDestination;

		this.onComplete = onComplete;

		this.wrapper = this.container.querySelector(".speech");

		this.synthesize = this.wrapper.dataset.synthesize;

		this.audioSource = null;

		this.pronounce = pronounce || new Object();

		this.colors = new Array();

		this.actions = new Array();

		this.webgl = new WebGL(this.wrapper);

		this.canvas = document.createElement("canvas");

		this.canvas.classList.add("2d");

		this.context = this.canvas.getContext("2d", {
			alpha: false
		});

		this.initialized = false;

		this.ready = false;

		this.visible = false;

		this.started = false;

		this.complete = false;

		this.text = "";

		this.textTarget = "";

		this.resize(false);

		this.scroll();

		this.textContainer = textContainer;

		this.initialize().then(()=>{

			this.ready = true;

			if( autoStart == true ){

				this.start();

			}

			this.events.add(this.webgl.canvas, "click", this.onClick, false);

			this.events.add(this.webgl.canvas, "mousemove", this.onMouseMove, false);

			this.events.add(window, "resize", event => this.resize(true), false);

			this.events.add(document, "scroll", this.scroll, false);

		});

		return this;

	}
	async initialize(){

		if( this.initialized != true ){

			this.initialized = true;

			this.webgl.addLayer({
				vertex: require("../../shaders/main-layer.vs"),
				fragment: require("../../shaders/main-layer.fs"),
				uniforms: {
					textTexture: this.canvas
				}
			});

			let randomImage = await new Promise(( resolve )=>{

				var image = new Image();

				image.addEventListener("load", event => resolve(image), false);

				image.src = "./assets/images/random.png";

			});

			this.postProcessing = this.webgl.addPass({
				fragment: require("../../shaders/post-processing.fs"),
				uniforms: {
					time: 0,
					randomTexture: randomImage,
					glitchIndex: 0,
					glitchForce: 0.01
				}
			});

			Timer.every(x => 1000 + Math.random() * 4000, Infinity, async ()=>{

				this.postProcessing.layer.uniforms.glitchIndex.value = Math.round(Math.random() * 3);

				this.postProcessing.layer.uniforms.glitchForce.value = 1;

				await Timer.await(500 + Math.random() * 500);

				this.postProcessing.layer.uniforms.glitchIndex.value = 0;

				this.postProcessing.layer.uniforms.glitchForce.value = 0.01;

			});

		}

		return this;

	}
	set textContainer( container ){

		this._textContainer = container instanceof HTMLElement ? container : this.wrapper;

		this.text = "";

		this.updateTextTarget();

	}
	get textContainer(){

		return this._textContainer || this.wrapper;

	}
	async updateTextTarget( handleAudio = true ){

		this.complete = false;

		var index = 0;

		this.colors.length = 0;

		this.actions.length = 0;

		this.textTarget = this.textContainer.innerText.split(/(#[a-z\-]+)/g).map(( chunk )=>{

			var color = DEFAULT_TEXT_COLOR;

			var action = null;

			var text = chunk.replace(/#([a-z\-]+)/g, ( match, type )=>{

				let variable = this.variables[type];

				if( variable?.color != undefined ){

					color = variable.color;

				}

				if( variable?.onClick != undefined ){

					action = variable.onClick;

				}

				return variable?.value || type;

			});

			if( action != null ){

				this.actions.push({ index, length: text.length, action });

			}

			index += text.length;

			this.colors.push({ index, color });

			return text;

		}).join("").toUpperCase();

		this.lines = (this.textTarget.match(/\n/g)?.length || 0) + 1;

		this.fontSize = this.canvas.height / this.lines;

		if( this.audioContext instanceof AudioContext && this.synthesize != undefined && handleAudio == true ){

			if( this.audioSource instanceof AudioNode ){

				this.audioSource.stop();

				this.audioSource.disconnect();

			}

			let filteredText = this.textTarget.toLowerCase().replace(/\n/g, " ");

			for( let key in this.pronounce ){

				let replacement = this.pronounce[key];

				filteredText = filteredText.replace(key.toLowerCase(), replacement);

			}

			let response = await fetch(`${ speechAPI }?text=${ encodeURIComponent(filteredText) }&language=${ this.synthesize }`);

			let buffer = await response.arrayBuffer();

			this.suspendedAudioContext = false;

			this.audioContext.decodeAudioData(buffer, ( audioBuffer )=>{

				this.audioSource = this.audioContext.createBufferSource();

				this.audioSource.buffer = audioBuffer;

				this.audioSource.connect(this.audioDestination);

				if( this.audioContext.state == "running" ){

					this.audioSource.start(0);

				}
				else {

					this.suspendedAudioContext = true;

				}

			});

		}

		return true;

	}
	start( playAudio = true ){

		if( this.started == false ){

			this.started = true;

			Timer.every(x => 40, Infinity, async ()=>{

				if( this.visible == true && this.complete == false ){

					this.update();

					if( this.text == this.textTarget || this.visible == false ){

						this.complete = true;

						if( this.onComplete instanceof Function ){

							this.onComplete();

						}

					}

				}

				return true;

			});

			if( this.suspendedAudioContext == true && playAudio == true ){

				this.suspendedAudioContext = false;

				this.audioSource.start(0);

			}

		}

		return this;

	}
	resize( updateText = true ){

		var { width, height } = this.wrapper.getBoundingClientRect();

		this.canvas.width = width * devicePixelRatio;

		this.canvas.height = height * devicePixelRatio;

		if( updateText == true ){

			this.updateTextTarget(false);

		}

		if( this.started ){

			this.update();

		}

		return this;

	}
	scroll(){

		var { top, height } = this.container.getBoundingClientRect();

		var bottom = top + height;

		if( (top >= 0 && top <= window.innerHeight) || (bottom >= 0 && bottom < window.innerHeight) || (top <= 0 && bottom >= window.innerHeight) ){

			this.visible = true;

			this.webgl.start();

		}
		else {

			this.visible = false;

			this.webgl.stop();

		}

		return this;

	}
	update(){

		this.text = this.textTarget.substring(0, this.text.length + 1);

		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

		this.context.font = `bold ${ this.fontSize }px Neue Haas Grotesk, sans-serif`;

		var lines = (this.text.match(/\n/g)?.length || 0) + 1;

		var x = 0;

		var y = (this.canvas.height / 2) + this.fontSize;

		for( let index = 0, length = this.text.length; index < length; index++ ){

			let character = this.text[index];

			let { color } = this.colors.find(({ index: begin })=>{

				return (index + 1) <= begin;

			});

			this.context.fillStyle = color;

			if( character == "\n" ){

				x = 0;

				y += this.fontSize;

			}
			else {

				let { width } = this.context.measureText(character);

				this.context.fillText(character, x, y - (lines * this.fontSize / 2));

				x += width;

			}

		}

		return this;

	}
	getActionOnPosition( pointerX, pointerY ){

		var { left, top } = this.webgl.canvas.getBoundingClientRect();

		var lines = (this.text.match(/\n/g)?.length || 0) + 1;

		var x = 0;

		var y = this.fontSize;

		this.context.font = `bold ${ this.fontSize }px Neue Haas Grotesk, sans-serif`;

		var action = null;

		for( let index = 0, length = this.text.length; index < length; index++ ){

			let character = this.text[index];

			let previousX = x;
			let previousY = y - this.fontSize;

			if( character == "\n" ){

				x = 0;

				y += this.fontSize;

			}
			else {

				let { width } = this.context.measureText(character);

				x += width;

			}

			if( pointerX >= previousX && pointerX <= x && pointerY >= previousY && pointerY <= y && /[\n\s\r]+/.test(character) == false ){

				let found = this.actions.find(({ index: begin, length })=>{

					return index >= begin && index <= (begin + length);

				});

				if( found != undefined ){

					action = found.action;

					break;

				}

			}

		}

		return action;

	}
	onClick( event ){

		var { left, top } = this.webgl.canvas.getBoundingClientRect();

		var pointerX = (event.clientX - left) * devicePixelRatio;

		var pointerY = (event.clientY - top) * devicePixelRatio;

		var action = this.getActionOnPosition(pointerX, pointerY);

		if( action != undefined ){

			action();

		}

		return this;

	}
	onMouseMove( event ){

		var { left, top } = this.webgl.canvas.getBoundingClientRect();

		var pointerX = (event.clientX - left) * devicePixelRatio;

		var pointerY = (event.clientY - top) * devicePixelRatio;

		var action = this.getActionOnPosition(pointerX, pointerY);

		if( action != undefined ){

			this.wrapper.classList.add("pointer");

		}
		else {

			this.wrapper.classList.remove("pointer");

		}

		return this;

	}
}